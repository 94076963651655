<template>
  <g id="g17">
    <g id="g324" class="dut_components">
      <TestPointView
        ref="testpoint_view"
        :testpointPlot="testpointPlot"
        :renderScale="renderScale"
        :sliderScale="sliderScale"
        :svgScale="0.6"
        :x_offset="3187"
        :y_offset="9019"
        :width="9879"
        :height="8005"
        :probeElement="probeElement"
        :btnRenderSide="btnRenderSide"
        :showRefs="showRefs"
        :searchRefString="searchRefString"
        :refSlider="refSlider"
        key="ma_260_tp"
        @toggleHoverText="toggleHoverText"
      />
      <GuidePinView
        ref="guidepin_view"
        :guidepinPlot="guidePins"
        :renderScale="renderScale"
        :sliderScale="sliderScale"
        :svgScale="0.8"
        :x_offset="2387"
        :y_offset="9019"
        :width="9879"
        :height="8005"
        :btnRenderSide="btnRenderSide"
        :showRefs="showRefs"
        :searchRefString="searchRefString"
        :refSlider="refSlider"
        key="ma_260_gp"
        @toggleHoverText="toggleHoverText"
      />
    </g>
  <Ma260Top v-show="showPlate && sideToggle != 2" />
  <Ma260Bottom v-show="showPlate && sideToggle != 1" />
  </g>
</template>
<script>
import interact from "interactjs";
export default {
  name: "Ma260",
  props: {
    testpointPlot: Array,
    guidePins: Array,
    renderScale: Number,
    svgScale: Number,
    sliderScale: Number,
    probeElement: Object,
    sideToggle: Number,
    btnRenderSide: Number,
    showRefs: Boolean,
    showPlate: Boolean,
    searchRefString: String,
    refSlider: Number,
  },
  components: {
    TestPointView: () => import("./TestPointView.vue"),
    GuidePinView: () => import("./GuidePinView.vue"),
    Ma260Top: () => import("./Ma260Top.vue"),
    Ma260Bottom: () => import("./Ma260Bottom.vue"),
  },
  data() {
    return {
      position: {
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    activateDragAndDrop() {
      interact(`.dut_components`).draggable({
        onmove: (event) => {
          this.position.x += event.dx * 12;
          this.position.y -= event.dy * 12;
          event.target.style.transform = `translate(${this.position.x}px, ${this.position.y}px)`;
        },
      });
    },
    toggleHoverText(payload) {
      this.$emit("toggleHoverText", payload);
    },
  },
  mounted() {
    this.activateDragAndDrop();
  },
};
</script>
